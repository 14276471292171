import LoginPage from "./LoginPage";
import RootPage from "./RootPage";
import EnterPromoCodePage from "./EnterPromoCodePage";
import RedeemListPage from "./RedeemListPage";

export default [
  { Page: LoginPage, route: "/login" },
  { Page: EnterPromoCodePage, route: "/promos/enter" },
  { Page: RedeemListPage, route: "/redeem/list" },
  { Page: RootPage, route: "/" }
];
