// @ts-ignore
import { gql } from "overmind-graphql";

// @ts-ignore
export const createSession = gql`
  mutation createSession {
    insert_session_one(object: {}) {
      id
    }
  }
`;
