import React, { useEffect, useState } from "react";
import { useActions, useAppState } from "./main";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useHistory,
  Redirect
} from "react-router-dom";

import SlAlert from "@shoelace-style/react/dist/alert";
import SlDialog from "@shoelace-style/react/dist/dialog";
import SlButton from "@shoelace-style/react/dist/button";
import LoginPage from "./pages/LoginPage";
import RootPage from "./pages/RootPage";
import routes from "./pages";
import LeftSidebar from "./components/LeftSidebar";
import { padr } from "./stitches.config";
import { DialogButton } from "./main/state";

const App = () => {
  const { redirectTo, isLoggedIn } = useAppState();
  const { clearRedirectTo, clickRootDialogButton } = useActions();

  function Redirector() {
    useEffect(() => {
      clearRedirectTo();
    });
    return <Redirect to={redirectTo} />;
  }
  return (
    <>
      <Router>
        <SlAlert />
        <SlDialog className="rootDialog" label="Are you sure?">
          <SlButton
            onClick={() => clickRootDialogButton({ button: DialogButton.yes })}
            type="danger"
            className={padr()}
          >
            Yes
          </SlButton>

          <SlButton
            onClick={() => clickRootDialogButton({ button: DialogButton.no })}
          >
            No
          </SlButton>
        </SlDialog>

        {isLoggedIn && (
          <>
            <LeftSidebar />
          </>
        )}

        <Switch>
          {routes.map(({ Page, route }) => (
            <Route exact path={route} component={Page} />
          ))}
        </Switch>

        {redirectTo && <Redirector />}
      </Router>
    </>
  );
};

export default App;
