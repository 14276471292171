import React from "react";
import { useActions, useAppState } from "../main";
import SlButton from "@shoelace-style/react/dist/button";
import SlForm from "@shoelace-style/react/dist/form";
import SlInput from "@shoelace-style/react/dist/input";
import SlQrCode from "@shoelace-style/react/dist/qr-code";
import { padl, padt } from "../stitches.config";

const LoginPage: React.FunctionComponent = () => {
  const { login } = useActions();
  const { id, gotError } = useAppState().session;

  if (gotError) {
    return <p>Please refresh your browser and try again.</p>;
  } else {
    return (
      <>
        <SlQrCode
          className={padt()}
          value={id}
          label="Scan this code to visit"
        />
        <div className={padl()}>
          <p>To use Portal</p>
          <ol>
            <li>Open Canpac Real Time Network on your phone</li>
            <li>Tap Setting (gear icon) and select Portal Scan</li>
            <li>Point your phone to this screen to capture the code</li>
          </ol>
        </div>
      </>
    );
  }
};

export default LoginPage;
