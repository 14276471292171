import { Context } from "../../index";
import { DialogButton } from "../../state";
// @ts-ignore
import { Socket as PhoenixSocket } from "phoenix";
export const createSession = async ({ state, effects, actions }: Context) => {
  try {
    const { sessionId } = await effects.api.createSession();
    console.log("sessionId", sessionId);
    state.session.id = sessionId;

    // watch the session for change
    // phone_number variable

    PhoenixSocket.prototype.endPointURL = function() {
      return process.env.GRAPHQL_WS_ENDPOINT;
    };
    effects.subscriptions.initialize({
      onDisconnected: actions.session.onDisconnected
    });
    effects.subscriptions.subcribeToSession(actions.session.onSession, {
      id: sessionId
    });

    // when  you get the number hit api again with id + number to get a token thats good for 1 hour
  } catch (error) {
    state.session.gotError = true;
    actions.toast({ error });
  }
};

export const onDisconnected = async ({ state, effects, actions }: Context) => {
  state.session.gotError = true;
};

export const onSession = async (
  { state, effects, actions }: Context,
  payload: any
) => {
  try {
    if (!payload) return;
    const { session_by_pk } = payload;
    const { phone_number } = session_by_pk;
    if (!phone_number) return;
    const sessionId = state.session.id;

    if (!sessionId) {
      return actions.toast({ message: "Session id is missing" });
    }

    const { token } = await effects.api.finalizeSession({
      phoneNumber: phone_number,
      sessionId
    });

    // when  you get the number hit api again with id + number to get a token thats good for 1 hour

    state.authToken = token;

    state.redirectTo = "/";
  } catch (error) {
    console.log(error);
    actions.toast({ error });
  }
};
