import React from "react";
import { useActions, useAppState } from "../main";
import SlButton from "@shoelace-style/react/dist/button";
import SlForm from "@shoelace-style/react/dist/form";
import SlInput from "@shoelace-style/react/dist/input";
import SlMenu from "@shoelace-style/react/dist/menu";
import SlMenuItem from "@shoelace-style/react/dist/menu-item";
import SlDivider from "@shoelace-style/react/dist/divider";
import SlFormatDate from "@shoelace-style/react/dist/format-date";

const Time: ({
  time,
  label
}: {
  time: Date | undefined | null;
  label: string;
}) => JSX.Element = ({
  time,
  label
}: {
  time: Date | null | undefined;
  label: string;
}) => {
  return (
    <>
      {time && (
        <small>
          <span>{label} </span>
          <SlFormatDate
            date={time}
            month="long"
            day="numeric"
            year="numeric"
            hour="numeric"
            minute="numeric"
          />
        </small>
      )}
      {!time && <small>{label}</small>}
    </>
  );
};

export default Time;
