import { Context } from "../../index";
import { create } from "domain";

export const cancelRedeem = async ({ state, effects, actions }: Context) => {
  state.promos.dryRun = false;
  state.promos.promo = undefined;
};

export const enterPromo = async (
  { state, effects, actions }: Context,
  { promoCode }: { promoCode: string }
) => {
  try {
    console.log(promoCode);
    const { promotion } = await effects.gql.queries.getPromo({ promoCode });

    console.log(promotion);
    if (promotion.length !== 1) {
      return actions.toast({ message: "Bad Promo Code" });
    }

    const {
      is_active,
      limit,
      promo_type,
      does_not_expire,
      description,
      name,
      promo_code,
      id
    } = promotion[0];

    state.promos.promo = {
      id,
      limit,
      name,
      description,
      active: is_active,
      promoCode: promo_code
    };

    state.promos.dryRun = true;
  } catch (error) {
    state.promos.dryRun = false;
    console.log(error);
    actions.toast({ error });
  }
};

export const redeemPromo = async ({ state, effects, actions }: Context) => {
  try {
    if (!state.promos.promo) {
      return actions.toast({ message: "Error: No promo" });
    }

    const promoId = state.promos.promo.id;
    if (!promoId) {
      return actions.toast({ message: "Error: No promo id" });
    }
    const { redeemId } = await effects.api.redeemPromo({ promoId });

    if (redeemId) {
      state.redirectTo = "/redeem/list";
      actions.toast({ message: "Redeemed, You can now convert" });
    }
  } catch (error) {
    state.promos.dryRun = false;
    state.promos.promo = undefined;
    actions.toast({ error });
  }
};

export const getRedeem = async ({ state, effects, actions }: Context) => {
  try {
    const { redeem } = await effects.gql.queries.getRedeem({});

    if (redeem.length) {
      state.promos.redeemList = redeem.map(
        ({
          created_at,
          id,
          phone_number,
          promotion_id,
          proof,
          state,
          promotion,
          promo_type
        }: any) => ({
          createdAt: created_at,
          id,
          phoneNumber: phone_number,
          promotionId: promotion_id,
          proof,
          state,
          promotion,
          promoType: promo_type
        })
      );
    }
  } catch (error) {
    actions.toast({ error });
  }
};

export const redeemToCredits = async (
  { state, effects, actions }: Context,
  { redeemId }: { redeemId: string }
) => {
  try {
    const { proof } = await effects.api.redeemToCredits({ redeemId });
    await actions.promos.getRedeem();
    actions.toast({ message: "Go in app to see updated credits" });
  } catch (error) {
    actions.toast({ error });
  }
};

export const redeemToShares = async (
  { state, effects, actions }: Context,
  { redeemId }: { redeemId: string }
) => {
  try {
    const { proof } = await effects.api.redeemToShares({ redeemId });
    await actions.promos.getRedeem();

    actions.toast({
      message: "Please follow link on screen to fill out form to get shares"
    });
  } catch (error) {
    actions.toast({ error });
  }
};
