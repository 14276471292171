import { gql } from "overmind-graphql";

// @ts-ignore
export const getPromo = gql`
  query MyQuery2($promoCode: String = "") {
    promotion(where: { promo_code: { _eq: $promoCode } }) {
      id
      created_at
      description
      does_not_expire
      expired_at
      is_active
      name
      limit
      promo_code
      promo_type
      sibilings_promotion_id
    }
  }
`;

// @ts-ignore
export const getRedeem = gql`
  query MyQuery {
    redeem {
      created_at
      id
      phone_number
      promotion_id
      proof
      state
      promo_type
      promotion {
        name
        created_at
        description
        does_not_expire
        expired_at
        id
        is_active
        limit
        promo_code
        promo_type
        sibilings_promotion_id
      }
    }
  }
`;
