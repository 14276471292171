import React, { CSSProperties } from "react";
import { useActions, useAppState } from "../main";
import SlButton from "@shoelace-style/react/dist/button";
import SlForm from "@shoelace-style/react/dist/form";
import SlInput from "@shoelace-style/react/dist/input";
import SlTextarea from "@shoelace-style/react/dist/textarea";
import SlDivider from "@shoelace-style/react/dist/divider";
import SlCheckbox from "@shoelace-style/react/dist/checkbox";
import SlSelect from "@shoelace-style/react/dist/select";
import SlIcon from "@shoelace-style/react/dist/icon";
import SlAnimation from "@shoelace-style/react/dist/animation";
import CSS from "csstype";
import { styleMap } from "lit-html/directives/style-map";
import { css } from "../stitches.config";
import { redeemPromo } from "../main/namespaces/promos/actions";
type CssStyleObject = CSSStyleDeclaration & CSSProperties;

const checkStyle = css({
  color: "green"
});

const EnterPromoCodePage: React.FunctionComponent = () => {
  const { enterPromo, cancelRedeem, redeemPromo } = useActions().promos;
  const { promo, dryRun } = useAppState().promos;
  // const { promoTypes } = useAppState().promos;

  return (
    <div>
      <SlForm
        onSlSubmit={e => {
          // @ts-ignore
          const promoCode = e.detail.formData.get("promoCode");

          // console.log({ name, description, expiredAt, doesNotExpire });
          enterPromo({
            promoCode
          });
        }}
      >
        <SlInput
          disabled={dryRun}
          name="promoCode"
          label="Promo Code"
          type="text"
          placeholder="eg. CANPAC2021"
          required
        >
          {dryRun && (
            <SlIcon slot="suffix" name="check-lg" className={checkStyle()} />
          )}
        </SlInput>
        <SlDivider />
        {dryRun && (
          <SlAnimation name="bounce" easing="ease-in-out" duration={2000} play>
            <SlButton onClick={() => redeemPromo()} type="primary">
              Redeem
            </SlButton>
          </SlAnimation>
        )}
        {!dryRun && <SlButton submit>Lookup</SlButton>}
        {dryRun && <SlButton onClick={() => cancelRedeem()}>Cancel</SlButton>}
      </SlForm>
      <SlDivider />
      {dryRun && promo && (
        <>
          <h3>{promo.name}</h3>
          <p>{promo.description}</p>
          <p>Click redeem to Redeem the Promo Code</p>
        </>
      )}
    </div>
  );
};

export default EnterPromoCodePage;
