import { Context } from ".";
import { DialogButton } from "./state";

export const onInitializeOvermind = async ({
  actions,
  effects,
  state
}: Context) => {
  effects.gql.initialize({
    // @ts-ignore
    endpoint: process.env.GRAPHQL_ENDPOINT,
    headers: () => {
      if (state.authToken) {
        return {
          authorization: `Bearer ${state.authToken}`
        };
      }
    }
  });

  effects.api.initialize({
    getParsedConfigurations() {
      // return state.editor.parsedConfigurations;
      return null;
    },
    provideJwtToken() {
      return state.authToken;
    }
  });

  if (!state.isLoggedIn) {
    state.redirectTo = "/login";
  }

  actions.session.createSession();
};
export const clearRedirectTo = ({ state }: Context) => {
  state.redirectTo = "";
};

export const dismissToast = () => {
  // @ts-ignore
  const list = document.getElementsByTagName("sl-alert");

  for (let item of list) {
    item.hide();
  }
};

export const toast = (
  { state }: Context,
  {
    error,
    message,
    duration,
    closable
  }: {
    error?: any;
    message?: string;
    duration?: number;
    closable?: boolean;
  }
) => {
  // const container = document.querySelector(".alert-toast-wrapper");
  // const button = container.querySelector("sl-button");
  let count = 0;

  // Always escape HTML for text arguments!
  function escapeHtml(html: string) {
    const div = document.createElement("div");
    div.textContent = html;
    return div.innerHTML;
  }

  // Custom function to emit toast notifications
  function notify({
    message,
    type = "primary",
    icon = "info-circle",
    duration = 3000,
    closable = true
  }: {
    message: string;
    type?: string;
    icon?: string;
    duration?: number;
    closable?: boolean;
  }) {
    let innerHTML = `
        <sl-icon name="${icon}" slot="icon"></sl-icon>
        ${escapeHtml(message)}
      `;
    if (!closable) {
      innerHTML = `
        <sl-spinner></sl-spinner>
        ${escapeHtml(message)}
    `;
    }

    const alert = Object.assign(document.createElement("sl-alert"), {
      type,
      closable,
      duration,
      innerHTML
    });

    document.body.append(alert);
    return alert.toast();
  }

  if (error) {
    if (error.response && error.response.data && error.response.data.message) {
      return notify({ message: error.response.data.message });
    }
    notify({ message: error.message });
  }
  if (message) {
    notify({ message, duration, closable });
  }
};

export const login = async (
  { state, effects, actions }: Context,
  { email, password }: { email: string; password: string }
) => {};

export const nav = async (
  { state, effects, actions }: Context,
  { route }: { route: string }
) => {
  state.redirectTo = route;
};

export const signOut = async ({ state, effects, actions }: Context) => {
  state.authToken = "";
  state.redirectTo = "/login";
};

export const confirmDialog = async ({
  state,
  effects,
  actions
}: Context): Promise<{ answer: DialogButton }> => {
  const rootDialog: any = document.querySelector(".rootDialog");

  return new Promise(resolve => {
    const evt = () => {
      rootDialog.removeEventListener("sl-hide", evt);
      // TODO: this okay? is this by reference?
      const answer = state.rootDialogClickedValue;
      state.rootDialogClickedValue = DialogButton.none;
      resolve({ answer: answer });
    };
    rootDialog.addEventListener("sl-hide", evt);
    rootDialog.show();
  });
};

export const clickRootDialogButton = async (
  { state, effects, actions }: Context,
  { button }: { button: DialogButton }
) => {
  state.rootDialogClickedValue = button;
  const rootDialog: any = document.querySelector(".rootDialog");
  rootDialog.hide();
};
