import React from "react";
import ReactDOM from "react-dom";
import { createOvermind } from "overmind";
import { Provider } from "overmind-react";
import { config } from "./main";
import App from "./App";

import "@shoelace-style/shoelace/dist/themes/light.css";
import "@shoelace-style/shoelace/dist/themes/dark.css";

import { css } from "./stitches.config";

const overmind = createOvermind(config, { devtools: true });

const root = css({
  padding: "1rem",
  display: "flex"
});

document.getElementById("app")!.classList.add(root());

ReactDOM.render(
  <Provider value={overmind}>
    <App />
  </Provider>,
  document.getElementById("app")
);
