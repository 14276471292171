import { graphql } from "overmind-graphql";
import { SubscriptionClient } from "subscriptions-transport-ws";
import { gql } from "overmind-graphql";
import * as promosQueries from "./promos/queries";
import * as sessionMutations from "./session/mutations";

type Subscriptions = {
  [index: string]: {
    unsubscribe: () => void;
  };
};

// @ts-ignore
const getSessionQuery = gql`
  subscription MySubscription($id: uuid!) {
    session_by_pk(id: $id) {
      phone_number
    }
  }
`;

export const subscriptions = (() => {
  let client: SubscriptionClient;
  let subscriptions: Subscriptions = {};

  return {
    initialize({ onDisconnected }: { onDisconnected: Function }) {
      // @ts-ignore
      client = new SubscriptionClient(process.env.GRAPHQL_WS_ENDPOINT, {
        reconnect: true
      });

      client.onDisconnected(() => {
        onDisconnected();
      });
    },
    subcribeToSession(action: any, variables: Object = {}) {
      let observable = client.request({
        // @ts-ignore
        query: getSessionQuery,
        variables
      });

      subscriptions["subcribeToSession"] = observable.subscribe({
        next: ({ data }) => {
          action(data);
        }
      });
    },
    unsubscribe(subscriptionName: string) {
      subscriptions[subscriptionName].unsubscribe();
    }
  };
})();

export default graphql({
  queries: {
    ...promosQueries
  },
  mutations: {
    ...sessionMutations
  }
});
