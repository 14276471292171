import apiFactory, { Api, ApiConfig, Params } from "./apiFactory";

let api: Api;

export default {
  initialize(config: ApiConfig) {
    api = apiFactory(config);
  },
  async createSession(): Promise<{ sessionId: string }> {
    const response = await api.post<{
      sessionId: string;
    }>("/createSession", {});
    return {
      sessionId: response.sessionId
    };
  },
  async finalizeSession({
    sessionId,
    phoneNumber
  }: {
    sessionId: string;
    phoneNumber: string;
  }): Promise<{ token: string }> {
    const response = await api.post<{
      token: string;
    }>("/finalizeSession", {
      sessionId,
      phoneNumber
    });
    return {
      token: response.token
    };
  },
  async redeemPromo({
    promoId
  }: {
    promoId: string;
  }): Promise<{ redeemId: string }> {
    const response = await api.post<{ redeemId: string }>("/redeemPromo", {
      promoId
    });
    return {
      redeemId: response.redeemId
    };
  },
  async redeemToCredits({
    redeemId
  }: {
    redeemId: string;
  }): Promise<{ proof: any }> {
    const response = await api.post<{ proof: any }>("/redeemToCredits", {
      redeemId
    });
    return {
      proof: response.proof
    };
  },
  async redeemToShares({
    redeemId
  }: {
    redeemId: string;
  }): Promise<{ proof: any }> {
    const response = await api.post<{ proof: string }>("/redeemToShares", {
      redeemId
    });
    return {
      proof: response.proof
    };
  },
  async version(): Promise<string> {
    const response = await api.get<{ version: string }>("/version");

    return response.version;
  }
};
