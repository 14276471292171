import React from "react";

import { css, menu } from "../stitches.config";
import SlMenu from "@shoelace-style/react/dist/menu";
import SlMenuItem from "@shoelace-style/react/dist/menu-item";
import SlDivider from "@shoelace-style/react/dist/divider";
import { useActions, useAppState } from "../main";

const sidebar = css({
  height: "100%",
  paddingRight: "1rem"
  // margin: "0.5rem"
});

const LeftSidebar: React.FunctionComponent = () => {
  const { nav, signOut } = useActions();

  return (
    <aside className={sidebar()}>
      <div>
        <SlMenu className={menu()}>
          <SlMenuItem
            onClick={() => {
              nav({ route: "/promos/enter" });
            }}
          >
            Enter Promo Code
          </SlMenuItem>
          <SlDivider />
          <SlMenuItem
            onClick={() => {
              nav({ route: "/redeem/list" });
            }}
          >
            Entered Promo History
          </SlMenuItem>
          <SlDivider />
          <SlMenuItem
            onClick={() => {
              signOut();
            }}
          >
            Sign out
          </SlMenuItem>
        </SlMenu>
      </div>
    </aside>
  );
};

export default LeftSidebar;
