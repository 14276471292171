import React from "react";
import { useActions, useAppState } from "../main";
import SlCard from "@shoelace-style/react/dist/card";

import { comlist, fillHeight, header } from "../stitches.config";
import SlIconButton from "@shoelace-style/react/dist/icon-button";
import SlButton from "@shoelace-style/react/dist/button";
// import Time from "../components/Time";
import SlCheckbox from "@shoelace-style/react/dist/checkbox";
import Time from "../components/Time";

const RedeemListPage: React.FunctionComponent = () => {
  const { getRedeem, redeemToCredits, redeemToShares } = useActions().promos;
  const { redeemList } = useAppState().promos;

  React.useEffect(() => {
    getRedeem();
  }, []);
  return (
    <>
      <div className={fillHeight()}>
        <div className={comlist()}>
          {redeemList.map(
            (
              {
                id,
                phoneNumber,
                proof,
                state,
                promotionId,
                createdAt,
                promotion,
                promoType
              },
              index
            ) => {
              return (
                <SlCard className={comlist()}>
                  <span className={header()} slot="header">
                    {promotion.name}/{promotion.description}
                  </span>
                  {state === "pending" && <p>Error</p>}
                  {state === "converted" && <p>Already Converted</p>}
                  {state === "open" && (
                    <SlButton
                      onClick={() => {
                        if (promoType.startsWith("CREDITS_")) {
                          return redeemToCredits({ redeemId: id });
                        }
                        if (promoType.startsWith("SHARES_")) {
                          return redeemToShares({ redeemId: id });
                        }
                      }}
                    >
                      Convert
                    </SlButton>
                  )}

                  <br />

                  <span slot="footer">
                    <Time label="Created at:" time={createdAt} />
                  </span>
                </SlCard>
              );
            }
          )}
        </div>
      </div>
    </>
  );
};

export default RedeemListPage;
