type Promo = {
  name: string;
  description: string;
  active: boolean;
  limit: number;
  promoCode: string;
  id: string;
};

type Redeem = {
  id: string;
  phoneNumber: string;
  proof: any;
  promotionId: string;
  createdAt: Date;
  promoType: string;
  promotion: any;
  state: string;
};

type State = {
  promoCode: string | undefined;
  dryRun: boolean; // only pull info does not try to redeem
  promo: Promo | undefined;
  redeemList: Redeem[];
};

export const state: State = {
  promoCode: undefined,
  dryRun: false,
  promo: undefined,
  redeemList: []
};
